export const envName = 'dev';
export const tenantName = `nebraskablue${envName}b2c`;
export const policyNameGL = 'B2C_1_GroupLeader_EOA_SignUpSignIn';
export const policyNameBroker = 'B2C_1_Broker_EOA_SignIn';
export const policyNameBrokerPassword = 'b2c_1_broker_password';
export const policyNameGLPassword = 'B2C_1_GroupLeader_EOA_PasswordReset';
export const environment = {
  envName: envName,
  tenantName: tenantName,
  policyNameBrokerPassword: policyNameBrokerPassword,
  policyNameGLPassword: policyNameGLPassword,
  policyNameBrokerRegister: 'B2C_1_Broker_EOA_SignUp',
  ConnectureURL: 'https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BROKER_CONNECTURE_SIGNIN_SAML/generic/login?EntityId=DRX2024StageAgent',
  // Litmos does not have a QA environment and auto creates users on SSO, so not adding a URL
  LitmosURL: '',
  WiproURL: 'https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BROKER_WIPRO_SIGNIN_SAML/generic/login?EntityId=https://sso.medadvantage360.com/auth/realms/app-stage',
  SAPUrl: 'https://sandbox.webcomserver.com/wpm/mt/bcbsneimp2',
  production: false,
  sapDomain: 'https://sandbox.webcomserver.com/wpm/',
  redirectUrlGL:`https://employer${envName}.nebraskablue.com/`,
  redirectUrlBroker:`https://brokers${envName}.nebraskablue.com/`,
  apiUrl: `https://employerapi${envName}.nebraskablue.com/api`,
  //redirectUrlGL: 'https://localhost:4200/',
  //redirectUrlBroker: 'https://localhost:4200/',
  //apiUrl: `http://localhost:7071/api`,
  imageUrl: `https://employersvc${envName}.nebraskablue.com/api`,
  b2cClientId: 'eaf565e3-5554-40d1-a85e-5098c0c369af',
  stsServerGL:  `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyNameGL}`,
  authWellknownEndpointGL: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyNameGL}/v2.0/`,
  stsServerBroker:  `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyNameBroker}`,
  authWellknownEndpointBroker: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyNameBroker}/v2.0/`,
  consentScopes: `openid  https://${tenantName}.onmicrosoft.com/api/full`,
  appInsights:{
    instrumentationKey: '4ec85276-ad5c-42d0-9fd4-ce8b4c6b0175'
  },
  MaintenanceMode: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
