import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormDataService } from '../../services/form-data.service';
import { ActivityLogService } from '../../../../services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SendDialogComponent } from 'src/app/glp-common/components/send-dialog/send-dialog.component';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { LoggingService } from 'src/app/utilities/logging.service';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forms-list',
  templateUrl: './forms-list.component.html',
  styleUrls: ['./forms-list.component.scss']
})
export class FormsListComponent implements OnInit, AfterViewInit {

  userEmail:string;
  groupId:string;
  isLoading:boolean=false;
  filterStr: string = '';
  dataSource: MatTableDataSource<any>;
  enableSearch: boolean;
  formType: string;
  idType:string;

  displayedColumns: string[] = ['group', 'name', 'downloadLink' ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private dataService: FormDataService,
    private logService: ActivityLogService,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private dialog: MatDialog,
    private loggingService: LoggingService,
    private memberData: MemberDataService
  ) { }

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
      if (account) {
        if(account.emails?.length>0){
          this.userEmail = account.emails[0];
        }
        this.groupId = account.extension_AccountNumber;
        this.formType = this.memberData.formType;
        if ((typeof this.groupId == 'undefined' || this.groupId.split(",").length > 1) && this.formType !== 'Individual') {
          this.groupId = this.memberData.selectedGroupID.toString();
        }

      //default idType is groupId
      if(this.memberData.idType == undefined || this.memberData.idType == null){
        this.idType = 'groupId';
      }
      else{
        this.idType = this.memberData.idType;
      }
      this.dataService.getFormList(this.formType,this.idType).subscribe(
        list => {
            let array = list.map(item => {
              return {
                $key: item.name,
                ...item
              };
            });
            this.dataSource = new MatTableDataSource(array);
            this.enableSearch = true;
            this.dataSource.filterPredicate = (data, filter) => {
              return this.displayedColumns.filter(dc=>dc != "downloadLink").some(ele => {
                return data[ele].toLowerCase().indexOf(filter) != -1;
              });
            };
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          });
      }
    });
  }

  ngAfterViewInit(){
  }

  applyFilter(filterValue: string) {
    this.filterStr = filterValue.trim();
    if(this.dataSource)
    {
      if (filterValue.trim() != "")
      {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filterPredicate = (data: any, filter: string) => {

          var c1 =  (typeof data.group !== 'undefined' && data.group !== null && data.group.toLowerCase().includes(filter) ||
            (typeof data.name !== 'undefined' && data.name !== null && data.name.toLowerCase().includes(filter))
          );
          return c1;
        };
        this.dataSource.filter = filterValue;
      }

      this.dataSource.filter = filterValue;
    }
  }

  clickFilter() {
    this.dataSource.filter = this.searchInput.nativeElement.value.trim().toLowerCase();
  }

  downloadFile(downloadUrl:string, formName:string){
    this.logService.log(this.userEmail,this.userService.isBrokerUser(),ActivityType.DownloadFormDocument,formName).subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );

    const link = document.createElement('a');
    link.setAttribute('href', downloadUrl.startsWith('http') ? downloadUrl : '//'+downloadUrl);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  sendFormEmail(downloadUrl:string, formName:string){
    this.logService.log(this.userEmail, this.userService.isBrokerUser(),ActivityType.SendFormDocument, formName).subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );

    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Send Form',
      message: "We will send the form to the email you indicate below.",
    };
    const confirmDialogRef = this.dialog.open(
      SendDialogComponent,
      confirmDialogConfig
    );
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.Closed) {
        this.isLoading=true;
        this.dataService
          .sendFormEmail(
            dialogResult.Email,
            formName,
            downloadUrl,
            this.userEmail,
            this.groupId,
            this.idType
          )
          .subscribe(
            (data) => {
              this.isLoading=false;
              const alertSuccessDialogConfig = new MatDialogConfig();
              if(data.startsWith("Invalid"))
              {
                alertSuccessDialogConfig.data = {
                  title: 'Form not available',
                  message: 'This Form is not available. Please reachout to EOA BCBSNE contact',
                  input: 'Close',
                };
              }
              else
              {
                alertSuccessDialogConfig.data = {
                  title: 'Success',
                  message: 'The form was sent successfully',
                  input: 'Close',
                };
              }
              this.dialog.open(AlertDialogComponent, alertSuccessDialogConfig);
            },
            (err) => {
              this.isLoading=false;
              this.loggingService.logException(err, 3);
              // console.log(err);
              let friendlyError = 'An error occurred while emailing Form';
              if (
                err.error &&
                err.error.Message &&
                err.error.Message.includes('Error')
              ) {
                friendlyError = err.error.Message.replace('Error:', '');
              }
              const errorDialogConfig = new MatDialogConfig();
              errorDialogConfig.data = {
                title: 'Error',
                message: friendlyError,
                input: 'Accept',
              };
              this.dialog.open(AlertDialogComponent, errorDialogConfig);
            }
          );
      }
    });
  }

  ts_map = {
    "Form Group":"ascending",
    "Form Name":"descending",
  }

 toggleSortingOrder(key: string) {
    if (key in this.ts_map) {
        for (const k in this.ts_map) {
          if (k !== key) {
              this.ts_map[k] = "descending";
          }
        }
        if (this.ts_map[key] === "descending") {
            window.dataLayer.push({'event': 'broker_click', 'click_location':'Forms', 'click_action': `Table Header - ${key} - ascending`});
            this.ts_map[key] = "ascending";
        } else {
            window.dataLayer.push({'event': 'broker_click', 'click_location':'Forms', 'click_action': `Table Header - ${key} - descending`});
            this.ts_map[key] = "descending";
        }
    } else {
        console.log(`${key} is not a valid key in the map.`);
    }
  }

  brokerClickPagination(event: any){
    if(event.srcElement.parentElement.ariaLabel != null){
      window.dataLayer.push({'event': 'broker_click', 'click_location': 'Forms', 'click_action': `Pagination - ${event.srcElement.parentElement.ariaLabel}`})
    }
   }

  pageSizeChange(newPageSize: number) {
    window.dataLayer.push({'event': 'broker_click', 'click_location':'Forms', 'click_action': `Filter - ${newPageSize} items per page`});
  }

  handleGtagClick(click_location: string, click_action: string): void {
    window.gTagPush(click_location, click_action)
  }

}
