
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { UserService } from '../../../../services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MemberDataService } from '../../services/member-data.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { IndividualInfo } from 'src/app/content/broker/models/IndividualInfo';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoggingService } from 'src/app/utilities/logging.service';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-individuallist',
  templateUrl: './individuallist.component.html',
  styleUrls: ['./individuallist.component.scss']
})
export class IndividuallistComponent implements OnInit {
  dataSource: MatTableDataSource<IndividualInfo>;
  displayedColumns: string[] = [
    'SubscriberName',
    'SubscriberID',
    'BrokerName',
    'BrokerID',
    'OriginalEffectiveDate',
    'ProductName',
    'icon'
  ];
  individuallist: any[];
  individualInfoList: IndividualInfo[];
  IndividualLoaded = true;
  enableSearch: boolean;
  filterStr: string = '';
  searchText: string;
  brokerIdFilter: string;
  selectedSubscriber: string;

  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  user = "";


  filteredValues =
    {
      BrokerID: '',
      OriginalEffectiveDate: '',
      ProductName: ''
    };


    @ViewChild(MatPaginator) paginator: MatTableDataSourcePaginator;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  constructor(
    private MemberDataService: MemberDataService,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private cdr: ChangeDetectorRef,
    private loggingService: LoggingService,
    private userService: UserService,
    private dialog: MatDialog,

  ) {}

  ngOnInit() {
    this.user = this.userService.getUser().firstName + " " + this.userService.getUser().lastName;
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
      const errorDialogConfig = new MatDialogConfig();

      this.MemberDataService.notify({ isReload: true });
      this.enableSearch = true;
      let userEmail = "";
      if (account.emails?.length > 0) {
        userEmail = account.emails[0];
      }
      
    if (account) {
        this.userService.getIndividualList(userEmail).subscribe((result) => {
          
          this.IndividualLoaded = true;
          //this.cdr.detectChanges();
          let individuals = [];
          this.individuallist = result.retList;
          let list = result.retList;
          
   
          if (!result.success)
          {
  
            if(result.status == 500){
              errorDialogConfig.data = {
                title: 'Individual Data Under Maintenance',
                message: 'We are experiencing technical difficulties and are actively working on a fix. Please try again later.',
                footermessage: 'Close the Browser to Exit',
              };
              var err = {
                name: 'Individual List is empty',
                message: 'Individual List is empty = ' + account.extension_AccountNumber,
                stack: 'Individual List is empty',
              };
              this.loggingService.logException(err, 3);
    
              this.dialog.open(AlertDialogComponent, errorDialogConfig);
              this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
            }
          }

          
          result.retList.forEach(ind => {
            let newInd = {
              SubscriberName: this.toCamelCase(ind.subscriberName),
              SubscriberID: ind.subscriberID,
              BrokerName: this.toCamelCase(ind.brokerName),
              BrokerageNameTxt: this.toCamelCase(ind.brokerageNameTxt),
              BrokerID: ind.brokerID,
              GroupID: ind.groupId,
              OriginalEffectiveDate: ind.originalEffectiveDate,
              ProductName: ind.productName,
              Address: ind.address,
              City: ind.city,
              StateCd: ind.stateCd,
              PostalCd: ind.postalCd,
              Email: ind.email,
              Phone: ind.phone

            }
            individuals.push(newInd);
          })
          
          
          this.individualInfoList = list;
          this.dataSource = new MatTableDataSource(individuals);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'OriginalEffectiveDate': {
                return new Date(item.OriginalEffectiveDate);
              }
              default: return item[property];
            }
          };
          
          this.IndividualLoaded = true;
          this.dataSource.filterPredicate = this.customFilterPredicate();
        })
    }
  });
  }
  
  toCamelCase(name: string): string {
    return name.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  exportToCSV() {
    const csvData = this.dataSource.filteredData.map(row => ({
      SubscriberName: this.toCamelCase(row.SubscriberName),
      SubscriberID: row.SubscriberID,
      BrokerName: this.toCamelCase(row.BrokerName),
      BrokerageNameTxt: this.toCamelCase(row.BrokerageNameTxt),
      BrokerID: row.BrokerID,
      OriginalEffectiveDate: row.OriginalEffectiveDate,
      ProductName: row.ProductName,
      Address: row.Address,
      City: row.City,
      StateCd: row.StateCd,
      PostalCd: row.PostalCd,
      Email: row.Email,
      Phone: row.Phone
    }));

    const header = Object.keys(csvData[0]);
    const csv = [
      header.join(','),
      ...csvData.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','))
    ].join('\r\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'Individual-data.csv');
  }

  customFilterPredicate() {

    const myFilterPredicate = (data: IndividualInfo, filter: string): boolean => {
      var globalMatch = !this.filterStr;
      console.log(this.filterStr);
      if (this.filterStr) {
        // search all text fields
        var globalMatch =
            (typeof data.SubscriberID !== 'undefined' && data.SubscriberID !== null && data.SubscriberID.includes(this.filterStr.toLowerCase()) ||
            (typeof data.SubscriberName !== 'undefined' && data.SubscriberName !== null && data.SubscriberName.toLowerCase().includes(this.filterStr.toLowerCase()) && this.toCamelCase(data.SubscriberName).toLowerCase().includes(this.filterStr.toLowerCase())) ||
            (typeof data.BrokerName !== 'undefined' && data.BrokerName !== null && data.BrokerName.toLowerCase().includes(this.filterStr.toLowerCase()) && this.toCamelCase(data.BrokerName).toLowerCase().includes(this.filterStr.toLowerCase())) ||
            (typeof data.BrokerID !== 'undefined' && data.BrokerID !== null && data.BrokerID.toLowerCase().includes(this.filterStr.toLowerCase())) ||
            (typeof data.OriginalEffectiveDate !== 'undefined' && data.OriginalEffectiveDate !== null && data.OriginalEffectiveDate.includes(this.filterStr.toLowerCase())) ||
            (typeof data.ProductName !== 'undefined' && data.ProductName !== null && data.ProductName.toLowerCase().includes(this.filterStr.toLowerCase()))

          );
          console.log(globalMatch);
      }

      // if none of fields contain the global search, then we just return, otherwise we look at column specific values
      if (!globalMatch) {
        return;
          }

      let filterValues = JSON.parse(filter);
      let allColumnsMatch = true;

      if(filterValues.BrokerID && filterValues.BrokerID != ('-Select Broker-')){
      // Broker id comes in the format of FirstName LastName (BrokerId)
      // logic below splits off firstname lastname and removes parentheses
        let brokerWritingNumber = filterValues.BrokerID.substring(filterValues.BrokerID.indexOf("(") + 1).split(')')[0];
        allColumnsMatch = (typeof data.BrokerID !== 'undefined' && data.BrokerID !== null && data.BrokerID.includes(brokerWritingNumber.toLowerCase())) && allColumnsMatch;
      }

      //convert data to month
      if(filterValues.OriginalEffectiveDate && filterValues.OriginalEffectiveDate != ('-Select Month-')){
        let monthIndex = parseInt(data.OriginalEffectiveDate) - 1;
        let month = this.months[monthIndex];
        allColumnsMatch = (typeof month !== 'undefined' && month !== null && month.toLowerCase().includes(filterValues.OriginalEffectiveDate.toLowerCase())) && allColumnsMatch;
      }

      if(filterValues.ProductName && filterValues.ProductName != ('-Select Product-')){
        allColumnsMatch = (typeof data.ProductName !== 'undefined' && data.ProductName !== null && data.ProductName.toLowerCase().includes(filterValues.ProductName.toLowerCase())) && allColumnsMatch;
      } 

      return allColumnsMatch;
    }
    return myFilterPredicate;
  }


  applyFilter() {
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  updateSearchText(filterValue: string){
    this.filterStr = filterValue.trim();
    this.applyFilter();
  }

  clickFilter() {
    this.dataSource.filter = this.searchInput.nativeElement.value.trim().toLowerCase();
  }


  updateBrokerIdFilter(brokerId: string){
    this.filteredValues.BrokerID = brokerId;
    this.applyFilter();
  }
  

  updateRenewalMonthFilter(renewalMonth: string){
    this.filteredValues.OriginalEffectiveDate = renewalMonth;
    this.applyFilter();
  }
  

  updateProductNameFilter(productName: string){
    this.filteredValues.ProductName = productName;
    this.applyFilter();
  }

  getBrokerIds(){
    return ['-Select Broker-',...new Set(this.individuallist.map(item => item.brokerName + " (" + item.brokerID + ") "))].sort();
  }

  getRenewalMonths(){
    return ['-Select Month-', ...this.months];
  }

  getProductNames(){
    return ['-Select Product-',...new Set(this.individuallist.map(item => item.productName))].sort();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator; // For pagination
    this.dataSource.sort = this.sort; // For sort
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'OriginalEffectiveDate': {
          return new Date(item.OriginalEffectiveDate);
        }
        default: return item[property];
      }
    };
  }


  openSubscriber(row: any) {
    this.MemberDataService.selctedSubscriber = row.SubscriberID;
    this.MemberDataService.selectedGroupName = row.SubscriberName;
    this.MemberDataService.selectedGroupID = row.GroupID;
    let product = row.ProductName;
    if(product.toLowerCase().includes('dental')){
      this.MemberDataService.productType = 'Dental';
    }
    else{
      this.MemberDataService.productType = 'Medical';
    }


    this.MemberDataService.idType = "individualId";
    this.MemberDataService.formType='Individual';
    this.MemberDataService.notify({isReload: true});
    this.router.navigate(['/home']);
  
  } 
}
