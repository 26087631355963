import { NotApprovedComponent } from './content/not-approved/not-approved/not-approved.component';
import { MasterAuthorizationGuard } from './guards/master-authorization.guard';
import { AutoLoginComponent } from './layout/auto-login/auto-login.component';
import { LogoutPageComponent } from './content/logout-page/logout-page.component';
import { DummyComponent } from './layout/dummy/dummy.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout-wrapper/layout.component';
import { HomeComponent } from './content/home/home.component';
import { FormsComponent } from './content/forms/forms.component';
import { DocumentsComponent } from './content/plan-documents/documents.component';
import { ReportsComponent } from './content/reports/reports.component';
import { ExpandedNotificationsComponent } from './content/expanded-notifications/expanded-notifications.component';
import { FaqComponent } from './content/faq/faq.component';
import { BrokerComponent } from './content/broker/broker.component';
import { ContactComponent } from './content/contact/contact.component';
import { GroupleaderlistComponent } from './content/home/components/groupleaderlist/groupleaderlist.component';
import { RegisterComponent } from './content/home/components/register/register.component';
import { CompleteRegistrationComponent } from './content/home/components/register/completeRegistration.component';
import { ActivateAccountComponent } from './content/activateAccount/activateaccount.component';
import { MAApplicationStatusComponent } from './content/maApplicationStatus/applicationstatus.component';
import { MaintenanceComponentBroker } from './content/Maintenance-page/maintenancebroker.component';
import { MaintenanceComponentGroupLeader } from './content/Maintenance-page/maintenanceGL.component';

const routes: Routes = [

  {
    path: 'home',
    component: LayoutComponent,
    canActivate:[MasterAuthorizationGuard],
    children:[
      {
        path: '',
        component: HomeComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'forms',
        component: FormsComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'GroupLeaderAccounts',
        component: GroupleaderlistComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'notifications',
        component: ExpandedNotificationsComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'help',
        component: FaqComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'broker',
        component: BrokerComponent,
        canActivate: [MasterAuthorizationGuard],
      },
      {
        path: 'activateAccount',
        component: ActivateAccountComponent,
        canActivate:[MasterAuthorizationGuard]
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [MasterAuthorizationGuard],
      },  
      { 
        path: 'maapplicationstatus', 
        component: MAApplicationStatusComponent,
        canActivate: [MasterAuthorizationGuard]
      },
      { 
        path: 'maintenanceModeBroker', 
        component: MaintenanceComponentBroker,
      },
      { 
        path: 'maintenanceModeGL', 
        component: MaintenanceComponentGroupLeader,
      },
    ]
  },
  { path: 'register', component: RegisterComponent},
  { path: 'completeRegistration', component: CompleteRegistrationComponent},
  { path: 'not-approved', component: NotApprovedComponent},
  { path: 'logout', component: LogoutPageComponent},
  { path: 'autologin', component: AutoLoginComponent},

  { path: '**', redirectTo: '' },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
