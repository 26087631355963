<!--Header for if a broker is logged in-->
<div id="levelOneHeader" *ngIf="showNavBar">
  <div id="logo">
    <img src="../../../assets/images/header/bcbsne-logo-white.png" (click)="handleGtagClick('NavBar', 'Logo Click'); navigateBroker()" class="link">
  </div>
  <ul id="main-menu" role="menu" class="nav navbar-nav">
    <div class="dropdown">
      <ul class="top-level-menu">
        <li>
          <a *ngIf="isActiveBroker" href="#" style="font-size: 18px; ">Dashboard ⮟</a>
          <ul class="second-level-menu">
            <li>
              <a href="{{brokerPortalUrl}}" (click)="handleGtagClick('NavBar','Dashboard - Home')">Home</a>
            </li>
            <li>
              <a href="#">  Small Group </a>
              <ul class="third-level-menu">
                <li><a href="{{smallGroupPortalUrl}}/Mga" (click)="handleGtagClick('NavBar','Dashboard - Small Group - Blue Pride New Sales')">Blue Pride New Sales</a></li>
                <li><a href="{{smallGroupPortalUrl}}/Renewal" (click)="handleGtagClick('NavBar','Dashboard - Small Group - Blue Pride Renewals')">Blue Pride Renewals</a></li>
                <li><a target="_blank" href="{{bavvySSOUrl}}">PersonalBlue</a></li>
                <li><a target="_blank" href="{{benefitFocusSSOUrl}}">BluesEnroll</a></li>
                <li><a target="_blank" href="{{empoweredMarginsSSOUrl}}" (click)="handleGtagClick('NavBar','Dashboard - Small Group - Quote ChamberChoice (UW Datahub)')">Quote ChamberChoice (UW Datahub)</a></li>
                <hr style="background-color: white" />
                <li><a href="{{smallGroupPortalUrl}}/Home/ToolsAndResources" (click)="handleGtagClick('NavBar','Dashboard - Small Group - Tools & Resources')">Tools & Resources</a></li>
              </ul>
            </li>
            <li>
              <a href="#">  Individual      </a>
              <ul class="third-level-menu">
                <li><p style="padding-top: 5px;"><strong>Under 65</strong></p></li>
                <li><a href="{{individualPortalUrl}}/ArmorHealth" (click)="handleGtagClick('NavBar','Dashboard - Individual - Under 65 - Armor Health New Applications')">Armor Health New Applications</a></li>
                <li><a href="{{individualPortalUrl}}/ArmorReEnroll" (click)="handleGtagClick('NavBar','Dashboard - Individual - Under 65 - Armor Health Re-Enrollments')">Armor Health Re-Enrollments</a></li>
                <li><a target="_blank" href="{{softheonSSOUrl}}" (click)="handleGtagClick('NavBar','Dashboard - Individual - Under 65 - Nebraska HeartlandBlue')">Nebraska HeartlandBlue</a></li>
                <li><p><strong>Over 65</strong></p></li>
                <li><a target="_blank" href="{{connectureSSOUrl}}">Medicare Quote & Enroll</a></li>
                <!-- <li><a target="_blank" href="{{wiproSSOUrl}}">Medicare Advantage</a></li> -->
                <li><a target="_blank" href="https://bcbsne.healthsparq.com/healthsparq/public/#/one/state=ne&postalCode=&country=&insurerCode=BCBSNE_I&brandCode=BCBSNE&productCode=0325000050" (click)="handleGtagClick('NavBar','Dashboard - Individual - Over 65 - Medicare Advatage Provider Service')">Medicare Advantage Provider Search</a></li>
                <li><a target="_blank" href="https://medicare.nebraskablue.com/MedicareAdvantage/PlanDetails2024" (click)="handleGtagClick('NavBar','Dashboard - Individual - Over 65 - Medicare Advantage Plan Details')">Medicare Advantage Plan Details</a></li>
                <li><a target="_blank" href="https://www.yourmedicaresolutions.com/mbrx-enroll-2024" (click)="handleGtagClick('NavBar','Dashboard - Individual - Over 65 - 2024 Medicare Blue Rx PDP Enrollment')">2024 Medicare Blue Rx PDP Enrollment</a></li>
                <li><a target="_blank" href="https://ne.medicarebluerxagent.com/" (click)="handleGtagClick('NavBar','Dashboard - Individual - Over 65 - MedicareBlue Rx Agent Portal')">MedicareBlue Rx Agent Portal</a></li>
                <li><a href="/home/maApplicationStatus" (click)="handleGtagClick('NavBar','Dashboard - Individual - Over 65 - Medicare Advantage Application Status')">Medicare Advantage Application Status</a></li>
                <li><a target="_blank" href="https://eyedoclocator.eyemedvisioncare.com/bcbsne/en">EyeMed Vision Provider Search</a></li>
                <li><a target="_blank" href="https://explore.fitonhealth.com/?utm_source=fitonhealth.com&utm_medium=link&utm_content=header">FitOn Fitness Facility Search</a></li>
                <li><p><strong>Ancillary</strong></p></li>
                <li><a target="_blank" href="https://www.nebraskablue.com/sso/Dental-Plans" (click)="handleGtagClick('NavBar','Dashboard - Individual - Ancillary - Dental Essentials')">Dental Essentials</a></li>
                <li><a target="_blank" href="https://www.nebraskablue.com/sso/Ancillary-Products/Individual-Options" (click)="handleGtagClick('NavBar','Dashboard - Individual - Ancillary - LifeSecure/Lumico')">LifeSecure/Lumico</a></li>
                <li><a target="_blank" href="https://www.geobluetravelinsurance.com/agent_landing.cfm" (click)="handleGtagClick('NavBar','Dashboard - Individual - Ancillary - GeoBlue')">GeoBlue</a></li>
                <hr style="background-color: white" />
                <li><a href="{{individualPortalUrl}}/Home/ToolsAndResources" (click)="handleGtagClick('NavBar','Dashboard - Individual - Tools & Resources')">Tools & Resources</a></li>
              </ul>
            </li>
            <li>
              <a href="#">  Reports   </a>
              <ul class="third-level-menu">
                <li><a href="{{smallGroupPortalUrl}}/TempID" (click)="handleGtagClick('NavBar','Dashboard - Reports - Group ID Card Reports')">Group ID Card Reports</a></li>
                <li><a href="{{smallGroupPortalUrl}}/Report" (click)="handleGtagClick('NavBar','Dashboard - Reports - Level Funded Reports')">Level Funded Reports</a></li>
                <li><a href="{{smallGroupPortalUrl}}/LargeGroup" (click)="handleGtagClick('NavBar','Dashboard - Reports - Large Group Reports')">Large Group Reports</a></li>
                <li><a target="_blank" href="https://seccomm.bcbsne.com/" (click)="handleGtagClick('NavBar','Dashboard - Reports - Tumbleweed SFTP')">Tumbleweed SFTP</a></li>
                <li><a target="_blank" href="{{bhiWhyzenSSOUrl}}" (click)="handleGtagClick('NavBar','Dashboard - Reports - Whyzen Analytics')">Whyzen Analytics</a></li>
              </ul>
            </li>
            <li>
              <a href="#">  Broker Management and Certification   </a>
              <ul class="third-level-menu">
                <li><a href="{{SAPSSOUrl}}">Broker Management</a></li>
                <li><a href="{{litmosSSOUrl}}">Product Training and Certification</a></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>

  </ul>
  <ul class="nav navbar-nav navbar-right service-nav" id="nav">
    <li class="nav-item nav-link" style="color: white;">
      <div>
        <a *ngIf="isActiveBroker" class="user-nav" style="color: white; padding-right: 10px; text-transform: none; text-decoration: none; cursor: pointer;" (click)="handleGtagClick(' Nav Bar','Action - Contact Us')" (click)="navigateContact();">
          Contact <i class="fa fa-phone "></i>
        </a>
      </div>
    </li>
    <li>

      <a *ngIf="isActiveBroker" class="user-nav" style="color: white; padding-right: 10px; text-transform: none; text-decoration: none;" href="{{passwordResetLink}}" (click)="handleGtagClick(' Nav Bar','Action - Settings')">
        Settings <i class="fa fa-gear "></i>
      </a>
    </li>
    <li>

      <a class="user-nav" style="color: white; padding-right: 10px; text-transform: none; text-decoration: none;" (click)="handleGtagClick(' Nav Bar','Action - Log Out')" (click)="logout()">
        Log Out <i class="fa fa-sign-out "></i>
      </a>
    </li>


  </ul>
</div>

<!--Header for if a group leader is logged in-->
<div id="levelOneHeaderGL" *ngIf="!showNavBar" style="height: 70px !important;">
  <div id="logo" style="padding-left: 25px;">
    <img src="../../../assets/images/header/bcbsne-logo-white.png" class="link">
  </div>
  <div class="inner-container-one">
    <div id="levelOneLinksContainer" style="padding-left: 50px;">
      <ul id="levelOneLinks">
        <li><a (click)="trackGroupList()" class="link">Home</a></li>
        <li><a routerLink="/home/help">Help</a></li>
      </ul>
    </div>
    <div id="user">
      <a class="user-nav" style="color: white; padding-right: 10px; text-transform: none; text-decoration: none;" (click)="logout()">
        Log Out <i class="fa fa-sign-out "></i>
      </a>
    </div>
  </div>
  <div></div>
</div>

<div *ngIf="showHeader || this.userService.groupLeaderAccountIsSelected" id="levelTwoHeader">

  <div></div>

  <div class="inner-container-two" *ngIf="!showNavBar">
   
    <div id="levelTwoLinks">
      <div *ngIf="showEHALinks">
        <a class="btn btn-default levelTwoMenuButton" target="_blank" href="https://www.nebraskablue.com/sso">
          EHA Benefit Resources
        </a>
      </div>
      <div *ngIf="showEHALinks">
        <a class="btn btn-default levelTwoMenuButton" target="_blank" href="https://eha.nebraskablue.com/">
          EHA WebPortal
        </a>
      </div>
      <a class="btn btn-default levelTwoMenuButton" target="_blank" href="https://bluesenroll.secure-enroll.com/go/bcbsne">
        Blues Enroll
      </a>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="navigateDocuments();">
          Plan Documents
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="navigateForms();">
          Forms
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="navigateReports();">
          Reports
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" style="padding-right: 0px;" type="button" (click)="navigateNotifications();">
          Notifications
        </button>
      </div>

    </div>
  </div>

  <div class="inner-container-two-broker" *ngIf="showNavBar">
    <div></div>
    <div id="levelTwoLinks">
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="trackHomeActionAndRedirect();" (click)="handleGtagClick('EOA Home Header','Page Links - Member List')">
          Member List
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="navigateDocuments();" (click)="handleGtagClick('EOA Home Header','Page Links - Plan Documents')">
          Plan Documents
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="navigateForms();" (click)="handleGtagClick('EOA Home Header','Page Links - Forms')">
          Forms
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" (click)="navigateReports();" (click)="handleGtagClick('EOA Home Header','Page Links - Reports')">
          Reports
        </button>
      </div>
      <div>
        <button class="btn btn-default levelTwoMenuButton" type="button" style="padding-right: 0px;" (click)="navigateNotifications();" (click)="handleGtagClick('EOA Home Header','Page Links - Notifications')">
          Notifications
        </button>
      </div>
    </div>
  </div>

  
</div>


<div *ngIf="showHeader || this.userService.groupLeaderAccountIsSelected" id="levelThreeHeader">
  <div></div>
  <div class="inner-container-three">
    <div id="groupNameHeader">
      <span>{{groupName}}</span>
    </div>
  </div>
  <div></div>
</div>
