import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenancebroker.component.html',
  styleUrls: ['./maintenancebroker.component.scss']
})
export class MaintenanceComponentBroker  {

  constructor() { }

  ngOnInit(): void {
  }
}
