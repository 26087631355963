import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ActivityLogService } from '../../services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { LoggingService } from 'src/app/utilities/logging.service';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  name: string;
  groupName: string = '';
  showNavBar: boolean = false;
  userEmail: string;
  isActiveBroker: boolean = false;
  brokerPortalUrl: string;
  smallGroupPortalUrl: string;
  individualPortalUrl: string;
  empoweredMarginsSSOUrl: string;
  softheonSSOUrl: string;
  bavvySSOUrl: string;
  benefitFocusSSOUrl: string;
  bhiWhyzenSSOUrl: string;
  passwordResetLink: string;
  wiproSSOUrl: string;
  connectureSSOUrl: string;
  litmosSSOUrl: string;
  SAPSSOUrl: string;
  showHeader: boolean = true;
  showEHALinks: boolean = false;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public userService: UserService,
    public oidcSecurityService: OidcSecurityService,
    private logService: ActivityLogService,
    private loggingService: LoggingService,
    private DataSource: MemberDataService,
  ) {}

  ngOnInit(): void {
    this.DataSource.currentMessageSubscriber.subscribe((reload: any) => {
      
      if(reload.isReload){
        this.LoadHeader();
      }
      if (this.userService.isBrokerUser() && this.groupName.length == 0) {
        this.showHeader = false;
      }
      if (this.userService.isGroupLeaderUser()) {
        if (!this.userService.groupLeaderAccountIsSelected) {
          this.showHeader = false;
        }
        if(this.userService.isGroupLeaderEHAUser()){
          this.showEHALinks = true;
        }
      }
      
    })
   
    if (this.userService.isBrokerUser()) {
      this.showNavBar = true;
    }


    this.wiproSSOUrl = environment['WiproURL'];
    this.connectureSSOUrl = environment['ConnectureURL'];
    this.litmosSSOUrl = environment['LitmosURL'];
    this.SAPSSOUrl = environment['SAPUrl'];
    this.setLinksBasedOnEnvironment();
    this.getPasswordResetLink();
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
      const errorDialogConfig = new MatDialogConfig();
      if (account) {
        if (!account.extension_Broker_IsBroker) {
          this.name = `${account.extension_FirstName} ${account.extension_LastName}`;
          let groupId = account.extension_AccountNumber;
          if (account.emails?.length > 0) {
            this.userEmail = account.emails[0];
          }
          
        }else{
          this.name = `${account.given_name} ${account.family_name}`;
        }

        this.isActiveBroker = account.extension_Broker_IsBroker && account.extension_Broker_IsActivated;

      } else {
        errorDialogConfig.data = {
          title: 'No Group Data',
          message: 'There is no Data for the Group you have registered. Please reachout to EOA BCBSNE contact.',
          footermessage: 'Close the Browser to Exit',
        };
        var err = {
          name: 'No Group Data',
          message: 'No Group Data = ' + account.extension_AccountNumber,
          stack: 'No Group Data',
        };
        this.loggingService.logException(err, 3);

        this.dialog.open(AlertDialogComponent, errorDialogConfig);
        this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
      }
    });


  }

  logout(): void {
    this.oidcSecurityService.logoff()
      .subscribe({
        next: (data) => {
        },
        error: (err) => {
        }
      });
    this.logService
      .log(this.userEmail, this.userService.isBrokerUser(),ActivityType.SignOut, '')
      .subscribe(
        (data) => { },
        (err) => {
          this.loggingService.logException(err, 3);
        }
      );
    this.router.navigate(['logout']);
  }

  navigateForms(): void {
    this.router.navigate(['/home/forms']);
  }

  navigateDocuments(): void {
    this.router.navigate(['/home/documents']);
  }

  navigateContact(): void {
    this.router.navigate(['/home/contact']);
  }

  navigateReports(): void {
    this.router.navigate(['/home/reports']);
  }

  navigateBroker(): void {
    this.router.navigate(['/home/broker']);
  }

  navigateGroupLeaderAccounts(): void {
    this.router.navigate(['/home/GroupLeaderAccounts']);
  }

  navigateNotifications(): void {
    this.logService
      .log(this.userEmail, this.userService.isBrokerUser(),ActivityType.OpenNotificationsSection, '')
      .subscribe(
        (data) => { },
        (err) => {
          this.loggingService.logException(err, 3);
        }
      );
    this.router.navigate(['/home/notifications']);
  }

  trackHomeActionAndRedirect() {
    this.logService.log(this.userEmail, this.userService.isBrokerUser(),ActivityType.Home, '').subscribe(
      (data) => { },
      (err) => {
        this.loggingService.logException(err, 3);
      }
    );
    
    this.router.navigate(['/home']);
  }

  trackGroupList() {
    var maintenancemode = environment['MaintenanceMode']; 
    if(maintenancemode){
      this.router.navigate(['/home/maintenanceModeGL']);
    }
    else {
      this.userService.groupLeaderAccountIsSelected = false;
      this.router.navigate(['/home/GroupLeaderAccounts']);
    }
  }

  LoadHeader(): void {
    if (this.groupName.length > 0) {
      this.groupName = '';
      return;
    }
    if(this.DataSource.selectedGroupName.length > 0){
      this.groupName = this.DataSource.selectedGroupName;
    }
    this.showHeader = true;
    
  }

  setLinksBasedOnEnvironment(): void {
    if (environment['envName'].toLowerCase() == 'prd') {
      this.brokerPortalUrl = "https://brokers.nebraskablue.com";
      this.individualPortalUrl = "https://individual.nebraskablue.com";
      this.smallGroupPortalUrl = "https://sgp.nebraskablue.com";
      this.softheonSSOUrl = "https://nebraskablueb2c.b2clogin.com/nebraskablueb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Softheon_SignIn_SAML/generic/login?EntityId=https://nebraskablue.softheon.com/broker";
      this.bavvySSOUrl = "https://nebraskablueb2c.b2clogin.com/nebraskablueb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Bavvy_SignIn_SAML/generic/login?EntityId=https://bavvy.com/APIGateway/api/auth/sso/acs";
      this.empoweredMarginsSSOUrl = "https://nebraska.uwdatahub.com/login?q=aW5pdGlhdGUtYjJjLWxvZ2luLWJyb2tlci1wb3J0YWw=";
      this.benefitFocusSSOUrl = "https://nebraskablueb2c.b2clogin.com/nebraskablueb2c.onmicrosoft.com/B2C_1A_BrokerSSO_BenefitFocus_SignIn_SAML/generic/login?EntityId=https://nebraskablue.benefitfocus.com";
      this.bhiWhyzenSSOUrl = "https://nebraskablueb2c.b2clogin.com/nebraskablueb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Whyzen_SignIn_SAML/generic/login?EntityId=https://nebraskablueb2c.onmicrosoft.com/bhiwhyzen";
    } else if (environment['envName'].toLowerCase() == 'dev') {
      this.brokerPortalUrl = "https://brokersdev.nebraskablue.com";
      this.individualPortalUrl = "https://individualdev.nebraskablue.com";
      this.smallGroupPortalUrl = "https://sgpdev.nebraskablue.com";
      this.empoweredMarginsSSOUrl = "https://nebraskastage.uwdatahub.com/login?q=aW5pdGlhdGUtYjJjLWxvZ2luLWJyb2tlci1wb3J0YWw=";
      this.benefitFocusSSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_BenefitFocus_SignIn_SAML/generic/login?EntityId=https://nebraskablue-test.benefitfocus.com";
      this.softheonSSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Softheon_SignIn_SAML/generic/login?EntityId=https://nebraskablue-model.softheon.com/broker";
      this.bavvySSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Bavvy_SignIn_SAML/generic/login?EntityId=https://stage.bavvy.com/APIGateway/api/auth/sso/acs";
      this.bhiWhyzenSSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Whyzen_SignIn_SAML/generic/login?EntityId=https://nebraskabluetstb2c.onmicrosoft.com/bhiwhyzen";
    } else if (environment['envName'].toLowerCase() == 'tst') {
      this.brokerPortalUrl = "https://brokerstest.nebraskablue.com";
      this.individualPortalUrl = "https://individualtst.nebraskablue.com";
      this.smallGroupPortalUrl = "https://sgptst.nebraskablue.com";
      this.empoweredMarginsSSOUrl = "https://nebraskastage.uwdatahub.com/login?q=aW5pdGlhdGUtYjJjLWxvZ2luLWJyb2tlci1wb3J0YWw=";
      this.benefitFocusSSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_BenefitFocus_SignIn_SAML/generic/login?EntityId=https://nebraskablue-test.benefitfocus.com";
      this.softheonSSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Softheon_SignIn_SAML/generic/login?EntityId=https://nebraskablue-model.softheon.com/broker";
      this.bavvySSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Bavvy_SignIn_SAML/generic/login?EntityId=https://stage.bavvy.com/APIGateway/api/auth/sso/acs";
      this.bhiWhyzenSSOUrl = "https://nebraskabluetstb2c.b2clogin.com/nebraskabluetstb2c.onmicrosoft.com/B2C_1A_BrokerSSO_Whyzen_SignIn_SAML/generic/login?EntityId=https://nebraskabluetstb2c.onmicrosoft.com/bhiwhyzen";
    }
  }

  getPasswordResetLink(): void {
    this.passwordResetLink = this.buildRoute()
  }

  private buildRoute(): string {
      const url = new URL(
        `https://${environment['tenantName']}.b2clogin.com/${environment['tenantName']}.onmicrosoft.com/oauth2/v2.0/authorize`
      );
      url.searchParams.append('p',(document.baseURI.includes('broker')) ? environment['policyNameBrokerPassword'] : environment['policyNameGLPassword']);
      url.searchParams.append('client_id', environment['b2cClientId']);
      url.searchParams.append('nonce', 'defaultNonce');
      url.searchParams.append(
        'redirect_uri', (document.baseURI.includes('broker')) ? environment['redirectUrlBroker'] : environment['redirectUrlGL']
      );
      url.searchParams.append('scope', environment['consentScopes'] ? environment['consentScopes'] : 'openid');
      url.searchParams.append(
        'response_type', 'id_token'
      );

      return url.href;
  }

  handleGtagClick(click_location: string, click_action: string): void {
    window.gTagPush(click_location, click_action)
  }

}
