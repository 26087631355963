import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponentBroker } from './maintenancebroker.component';



@NgModule({
  declarations: [MaintenanceComponentBroker],
})
export class MaintenanceModule { }
